import Cookies from 'js-cookie';

/**
 * Global Frontend JS - loaded on all pages
 * 
 * Destroys the participants cookie if the user is redirected
 * from the course page after completion of the registration
 */
(function ($) {

    const PSCGlobal = {

        init: () => {
            
            if( psFrontGlobal.psRedirectType && psFrontGlobal.eid ) {
                let participants = Cookies.get('ps_participants');
                if( participants ) {
                    Cookies.remove( 'ps_participants', { path: '/' } );
                }
            }
            
        }

    }

    jQuery(function($){
        PSCGlobal.init();
    });

})(jQuery);